<template>
  <v-container>
    <v-layout text-center wrap>
      <v-flex xs12>
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        ></v-img>
      </v-flex>

      <v-flex mb-4>
        <h1 class="display-2 font-weight-bold mb-3">
          お知らせ一覧
        </h1>
      </v-flex>
    </v-layout>

    <!-- テーブル要素 -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="infomationList"
        :search="search"
        :items-per-page="10"
        :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
        :options.sync="informationListOptions"
        item-key="date"
        class="elevation-1"
      >
        <template v-slot:top> </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld03',

    data: () => ({
      ingleSelect: false,
      selected: [],
      headers: [
        {
          text: '掲載日',
          sortable: true,
          value: 'date',
          align: 'left',
          width: '15%'
        },
        { text: 'タイトル', value: 'title', align: 'left', width: '75%' },
        {
          text: '表示/非表示',
          value: 'dispFlag',
          align: 'center',
          width: '10%'
        }
      ],
      infomationList: [
        {
          date: '2020-02-05',
          title: 'お知らせ内容その1',
          dispFlag: ''
        },
        {
          date: '2020-02-06',
          title: 'お知らせ内容その2',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その3',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その4',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その5',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その6',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その7',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その8',
          dispFlag: ''
        },
        {
          date: '2020-02-05',
          title: 'お知らせ内容その1-1',
          dispFlag: ''
        },
        {
          date: '2020-02-06',
          title: 'お知らせ内容その2-1',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その3-1',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その9',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その10',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その11',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その12',
          dispFlag: ''
        },
        {
          date: '2020-02-07',
          title: 'お知らせ内容その13',
          dispFlag: ''
        }
      ],
      informationListOptions: {
        sortBy: ['date'],
        sortDesc: [true]
      },
      informationListOptions_02: {
        sortBy: ['title'],
        sortDesc: [false]
      }
    })
  };
</script>
