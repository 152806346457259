// import Amplify from 'aws-amplify';

// --------------------------------
// NOTE
// 環境変数VUE_APP_STAGEが未定義の場合は開発環境として接続
// 基本的にはプロジェクト直下の.envまたは.env.localファイルでVUE_APP_STAGEを定義すること
// amplifyでのビルド後にAmplifyコンソールから設定した環境変数を.envファイルに書き込み、
// デプロイ先に配置され、アプリケーション起動時に読み込まれる
// --------------------------------

const stage = process.env.VUE_APP_STAGE ?? 'develop';
export default {
  API: {
    endpoints: [
      {
        name: 'rebax',
        endpoint: `https://9d7opnai52.execute-api.ap-northeast-1.amazonaws.com/${stage}`
      }
    ]
  }
};
