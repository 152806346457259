import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import { Config, CognitoIdentityCredentials } from 'aws-sdk';
import config from './config';

export default class Cognito {
  configure() {
    if (config.userPool) {
      this.userPool = config.userPool;
    } else {
      this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId
      });
    }
    Config.region = config.region;
    Config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId
    });
    this.options = config;
  }

  static install = (Vue, options) => {
    Object.defineProperty(Vue.prototype, '$cognito', {
      get() {
        return this.$root._cognito;
      }
    });

    Vue.mixin({
      beforeCreate() {
        if (this.$options.cognito) {
          this._cognito = this.$options.cognito;
          this._cognito.configure(options);
        }
      }
    });
  };

  signIn() {
    // サインインするユーザーは固定かつサインインを必須にするため、
    // ユーザー名とパスワードは環境変数から渡す
    const username = process.env.VUE_APP_COGNITO_USER;
    const password = process.env.VUE_APP_COGNITO_PASSWORD;

    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);

    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          reject(err);
        }
      });
    });
  }
  isSignedIn() {
    return this.userPool.getCurrentUser() !== null;
  }

  async getJwtToken() {
    if (!this.isSignedIn()) {
      await this.signIn();
    }

    // NOTE: セッション切れ時のリフレッシュはSDK側が処理するため記述不要
    const cognitoUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            resolve(session.getIdToken().getJwtToken());
          }
        }
      });
    });
  }
}
