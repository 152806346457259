import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import VueHead from 'vue-head';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Amplify, { Auth, Analytics } from 'aws-amplify';
import awsconfig from './aws-exports';
import cognito from './plugins/cognito';

/* 内部コンポーネント */
import App from './App.vue';
import WorkManagement from './WorkManagement.vue';
import Simulate from './Simulate.vue';
import PurchaseSearch from './PurchaseSearch.vue';
import CompanyHomePage from './CompanyHomePage.vue';

/* 外部コンポーネント */
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.use(VueHead);
Vue.use(VueRouter); // VueRoutreを使用

/**
 * 金額をカンマ区切りに表示します。
 * @param value 金額
 */
Vue.filter('addComma', function(value) {
  return value.toLocaleString();
});

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Simulate },
    { path: '/purchase-search', component: PurchaseSearch },
    { path: '/search', component: CompanyHomePage },
    { path: '/work-management', component: WorkManagement }
  ]
});

Vue.use(VueAxios, axios);

Amplify.configure(awsconfig);
const authConfig = {
  Auth: {
    identityPoolId: 'ap-northeast-1:2e6972d3-c950-4678-886d-7d94c88ed586',
    region: 'ap-northeast-1'
  }
};
//Initialize Amplify
Auth.configure(authConfig);
const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: '3b3e21ef837d423bbaf87485994da2b2',
    // Amazon service region
    region: 'ap-northeast-1',
    mandatorySignIn: false
  }
};
Analytics.configure(analyticsConfig);
Analytics.updateEndpoint({
  attributes: { stage: [process.env.VUE_APP_STAGE ?? 'develop'] }
});

new Vue({
  router,
  vuetify,
  Amplify,
  cognito,
  icons: {
    iconfont: 'fa'
  },
  render: h => h(App)
}).$mount('#app');
