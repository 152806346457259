<template>
  <div id="app">
    <v-app style="background: #f5f5f5">
      <!-- 幅が広いときのヘッダー -->
      <v-app-bar app color="white" v-if="!this.$vuetify.breakpoint.xs">
        <v-toolbar-title>
          <a href="https://nippon-rebax.co.jp/" target="_blank">
            <img
              src="./assets/images/rebax/nippon-rebax-logo.png"
              alt="株式会社日本リバックス"
              style="max-height: 64px"
            />
          </a>
        </v-toolbar-title>
        <v-tabs align-with-title>
          <v-spacer></v-spacer>
          <v-tab to="/"> 物件価格査定フォーム </v-tab>
          <!-- 価格情報の登録が進み次第、コメントアウトを解除 -->
          <!--
          <v-tab to="/purchase-search"> 買取価格検索 </v-tab>
          -->
        </v-tabs>
      </v-app-bar>
      <!-- 幅が狭いときのヘッダー -->
      <v-app-bar app color="white" v-else>
        <v-toolbar-title class="mx-auto">
          <img
            src="./assets/images/rebax/nippon-rebax-logo.png"
            alt="株式会社日本リバックス"
            style="max-height: 64px"
          />
        </v-toolbar-title>
        <template v-slot:extension>
          <v-tabs centered>
            <v-tab to="/"> 物件価格査定フォーム </v-tab>
            <!-- 価格情報の登録が進み次第、コメントアウトを解除 -->
            <!-- 
            <v-tab to="/purchase-search"> 買取価格検索 </v-tab>
            -->
          </v-tabs>
        </template>
      </v-app-bar>
      <!-- アプリケーションのコンポーネントに基づいてコンテンツのサイズを決定 -->
      <!-- <v-main style="width:300px;"> -->
      <v-main class="pt-5">
        <!-- アプリケーションに適切なgutterを提供します -->
        <v-content>
          <v-container fluid>
            <!-- vue-routerを使用している場合 -->
            <router-view></router-view>
          </v-container>
        </v-content>
      </v-main>
      <!-- <v-navigation-drawer> -->
      <!-- -->
      <!-- </v-navigation-drawer> -->

      <v-footer app outlined id="rebax-footer">
        <p class="pr">
          Copy right © 株式会社日本リバックス All Rights Reserved.
        </p>
      </v-footer>
    </v-app>
  </div>
</template>
<style scoped>
  footer#rebax-footer {
    background-color: #f2f2f2;
  }
  footer#rebax-footer .pr {
    color: #a4a4a4;
    width: 100%;
    margin-bottom: 0;
    padding: 0.4em 0;
  }
</style>
<script>
  // NOTE: 月は実際の月 -1 の値を指定しているので、実際は2021/10/10 0:0を示している
  const cacheActivateDate = new Date(2021, 9, 10, 0, 0);
  const noCache = [
    {
      'http-equiv': 'Pragma',
      content: 'no-cache'
    },
    {
      'http-equiv': 'Cache-Control',
      content: 'no-cache'
    },
    {
      'http-equiv': 'Expires',
      content: '0'
    }
  ];

  export default {
    head: {
      meta: new Date() > cacheActivateDate ? [] : noCache,
      script: [
        {
          type: 'text/javascript',
          src: 'https://www.googletagmanager.com/gtag/js?id=G-CBKWV2TZGS',
          async: true
        }
      ]
    }
  };
</script>
