<template>
  <v-app>
    <!-- v-navigation-drawer→サイドバー -->
    <!-- v-model="任意"→ -->
    <!-- clipped→ナビゲーションバーの下にくるようにする -->
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-container temporary>
        <v-list>
          <!-- v-list-item-group→メニュークリック字のエフェクトをつける -->
          <v-list-item-group>
            <!-- Json形式の配列を拡張for文っぽく回す -->
            <!-- v-list-item→繰り返し表示させることでコンテンツが増える -->
            <v-list-item
              v-for="(sideMenuItem, i) in sideMenuItems"
              :key="i"
              v-on:click="setSelectedSideMenu(sideMenuItem.index)"
            >
              <vlist-item-content>
                <!-- class="title"を設定すると文字の大きさが変わる -->
                <v-list-item-title>
                  {{ sideMenuItem.index }} {{ sideMenuItem.text }}
                </v-list-item-title>
              </vlist-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <!-- v-app-bar→ナビゲーションバー -->
    <!-- dark→色指定 -->
    <!-- app→ナビゲーションバーの高さが自動調整される -->
    <!-- clipped-left→ナビゲーションバーの下にくるようにする -->
    <v-app-bar coler="primary" dark app clipped-left>
      <!-- v-app-bar-nav-icon→サイドバー表示のためのハンバーガーメニュー -->
      <!-- ナビゲーションバーに表示したいので、ここに追加 -->
      <!-- @click→onclick処理 drawerに変化が起きたら -->
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title>社内トップページ</v-toolbar-title>
      <!-- v-spacer→ちょうどいい感じのスペース -->
      <v-spacer></v-spacer>
      ようこそ {{ userName }} さん
      <v-toolbar-items>
        <!-- v-btn→ボタン -->
        <!-- text→ボタンの透明化 -->
        <v-btn text>ログイン</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <!-- v-content→メイン画面 -->
    <v-content>
      <!-- {{ displayComponent }} -->
      <HelloWorld msg="test" v-if="selectedSideMenu === 1" />
      <HelloWorld02 msg="test" v-if="selectedSideMenu === 2" />
      <HelloWorld03 msg="test" v-if="selectedSideMenu === 3" />
      <HelloWorld99 msg="test" v-if="selectedSideMenu === 99" />
    </v-content>
    <!-- v-footer→フッター -->
    <v-footer coler="primary" dark app>
      フッター要素 selectedSideMenu={{ selectedIndex }} displayComponent={{
        displayComponent
      }}
    </v-footer>
  </v-app>
</template>

<script>
  import HelloWorld from './components/HelloWorld';
  import HelloWorld02 from './components/HelloWorld02';
  import HelloWorld03 from './components/HelloWorld03';
  import HelloWorld99 from './components/HelloWorld_template';

  export default {
    // 初期値等の設定が行える
    data() {
      return {
        drawer: null,
        userName: 'ゲスト',
        sideMenuItems: [
          { index: 1, text: 'トップページ' },
          { index: 2, text: 'お知らせ' },
          { index: 3, text: '工事中' },
          { index: 99, text: 'テンプレート' }
        ],
        selectedSideMenu: 1
      };
    },
    components: {
      HelloWorld,
      HelloWorld02,
      HelloWorld03,
      HelloWorld99
    },
    computed: {
      // component切り替え内容確認のためフッター更新
      displayComponent: function() {
        if (this.selectedSideMenu === 2) {
          return '<HelloWorld02 msg = "test" v-if="selectedSideMenu===2"/>';
        } else if (this.selectedSideMenu === 3) {
          return '<HelloWorld03 msg = "test" v-if="selectedSideMenu===3"/>';
        } else if (this.selectedSideMenu === 99) {
          return '<HelloWorld_template msg = "test" v-if="selectedSideMenu===99"/>';
        }
        return '<HelloWorld msg = "test" v-if="selectedSideMenu===1"/>';
      },
      // サイドメニュー選択内容確認のためフッター更新
      selectedIndex: function() {
        return this.selectedSideMenu;
      }
    },
    methods: {
      // サイドメニュー選択時の挙動
      setSelectedSideMenu: function(value) {
        // alert(value);
        this.selectedSideMenu = value;
      }
    }
  };
</script>
