<template>
  <v-container>
    <v-layout text-center wrap>
      <v-flex mb-4>
        <h1 class="display-2 font-weight-bold mb-3">
          お知らせ一覧
        </h1>
      </v-flex>
    </v-layout>

    <!-- テーブル要素 -->
    <div id="information">
      <table class="table table-borderd">
        <thead class="thead-dark">
          <tr>
            <th class="date">掲載日</th>
            <th class="title">タイトル</th>
            <th class="delete">削除</th>
          </tr>
        </thead>
        <tbody>
          <!-- v-forで繰り返す要素を日付の降順にする -->
          <tr v-for="item in reverseItems" v-bind:key="item.date">
            <td>{{ item.date }}</td>
            <td>{{ item.title }}</td>
            <td class="checkbox">
              <input type="checkbox" v-model="checked" />
              <label for="checkbox">{{ checked }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld02',
    data: () => ({
      informationHeaders: [
        { text: '掲載日', align: 'left' },
        { text: 'タイトル', align: 'left' }
      ],
      informationItems: [
        { id: 1, date: '2020-02-05', title: 'タイトルのテスト' },
        { id: 2, date: '2020-02-06', title: 'タイトルのテスト その2' },
        { id: 3, date: '2020-02-07', title: 'タイトルのテスト その3' }
      ]
    }),
    computed: {
      // 配列の要素順番を逆順にする
      reverseItems() {
        return this.informationItems.slice().reverse();
      }
    }
  };
</script>

<style>
  * {
    box-sizing: border-box;
  }
  #information {
    max-width: 640px;
    margin: 0 auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead th {
    border-bottom: 2px solid #000000;
    color: #000000;
  }
  th,
  th {
    padding: 0 8px;
    line-height: 40px;
  }
  thead th.date {
    width: 50px;
    text-align: left;
  }
  thead th.title {
    width: 100px;
    text-align: left;
  }
  thead th.delete {
    width: 60px;
    text-align: right;
  }
  tbody td.checkbox {
    text-align: right;
  }
  tbody td.title {
    text-align: left;
  }
  tbody tr td,
  tbody tr th {
    border-bottom: 1px solid #ccc;
    transition: all 0.4s;
  }
  tbody tr.done td,
  tbody tr.done th {
    background: #f8f8f8;
    color: #bbb;
  }
  tbody tr:hover td,
  tbody tr:hover th {
    background: #f4fbff;
  }
</style>
