<template>
  <v-container>
    <!--
      TODO: 
      全体的なデザイン調整
    -->
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar elevation="3">
            <span class="text-h5">物件価格検索</span>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  style="border-right: solid thin; border-color: rgba(0, 0, 0, 0.12);"
                  cols="12"
                  sm="6"
                >
                  <p class="text-h6">検索条件を入力してください。</p>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <!-- 
                            NOTE: 
                            hint/placeholderは対応するpersistent-〇〇属性を与えることで常時表示に
                            顧客の要望次第で変更の可能性あり
                           -->
                          <v-text-field
                            name="buildingName"
                            id="buildingName"
                            v-model="buildingName"
                            autocomplete="off"
                            label="マンション名"
                            placeholder="例:〇〇マンション"
                            clearable
                            autofocus
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            name="roomNumber"
                            label="部屋番号"
                            id="roomNumber"
                            v-model="roomNumber"
                            placeholder="例:101"
                            hint="未入力の場合、すべての部屋が表示されます"
                            persistent-hint
                            autocomplete="off"
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="6">
                          <v-btn width="100%" color="info" @click="search"
                            >買取価格検索</v-btn
                          >
                        </v-col>
                        <v-col cols="6">
                          <v-btn width="100%" color="error" @click="inputReset"
                            >リセット</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-col>
                <!-- NOTE: v-dividerの幅でスタイルが崩れないか要確認 -->
                <v-divider
                  :class="{ 'mx-1': this.$vuetify.breakpoint.xs }"
                  :vertical="!this.$vuetify.breakpoint.xs"
                ></v-divider>
                <v-col cols="12" sm="6">
                  <p class="text-h6">日本リバックス買取価格(目安)</p>
                  <p
                    v-if="roomsCount <= 1"
                    :class="{ 'pl-6': !this.$vuetify.breakpoint.xs }"
                    class="text-center text-sm-left text-h4 font-weight-bold font-italic purchase-price"
                  >
                    {{ purchasePrice | addComma }}円
                  </p>
                  <p
                    v-else
                    :class="{ 'pl-6': !this.$vuetify.breakpoint.xs }"
                    class="text-center text-sm-left text-h4 font-weight-bold font-italic purchase-price"
                  >
                    {{ minPurchasePrice | addComma }} 〜
                    <br v-if="this.$vuetify.breakpoint.xs" />
                    {{ maxPurchasePrice | addComma }} 円
                  </p>
                  <v-simple-table
                    v-if="roomsCount > 1"
                    class="search-result-table"
                  >
                    <thead>
                      <tr>
                        <th class="text-subtitle-1 font-weight-bold">
                          部屋番号
                        </th>
                        <th class="text-subtitle-1 font-weight-bold">
                          買取価格
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="room in rooms" :key="room.roomNumber">
                        <td class="font-weight-bold">{{ room.roomNumber }}</td>
                        <td class="text-subtitle-1 font-weight-bold">
                          {{ room.purchasePrice | addComma }}円
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <p class="text-caption indent">
                    ※本価格はあくまで参考価格となり、実際の買取金額とは異なることがあります。詳しくはお問合せください。
                  </p>
                  <p class="text-center text-sm-left">
                    <v-btn color="info" style="width:45%">お問合せ</v-btn>
                  </p>
                  <p class="text-left text-sm-right text-caption indent">
                    ※物件の諸条件より価格を算出したい場合は<router-link to="/"
                      >こちら</router-link
                    >
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { API } from 'aws-amplify';
  const apiName = 'rebax';

  export default {
    beforeMount() {
      document.title = '物件価格検索 | 株式会社日本リバックス';
    },
    data() {
      return {
        purchasePrice: '---',
        minPurchasePrice: Infinity,
        maxPurchasePrice: 0,
        buildingName: undefined,
        roomNumber: undefined,
        rooms: []
      };
    },
    computed: {
      roomsCount() {
        return this.rooms.length;
      }
    },
    methods: {
      async search() {
        // TODO: バリデーションの実行をinputからフォーカスが外れたのタイミングに変更
        // 全てのバリデーションが通った場合に計算ボタンを有効化のほうがベター?
        let errorMsg = [];
        if (!this.buildingName) {
          errorMsg.push('マンション名を入力してください');
        }
        if (errorMsg.length > 0) {
          alert(errorMsg.join('\n'));
          this.return;
        }

        // APIからデータ取得
        const token = await this.$cognito.getJwtToken();
        const myInit = {
          response: true,
          headers: { Authorization: token }
        };
        const resourcePath = [
          '/purchase_price',
          this.buildingName,
          this.roomNumber
        ].join('/');
        const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
          e => ({
            error: e
          })
        );

        if (apiResponse.error) {
          // window.console.log(
          //   JSON.parse(JSON.stringify(apiResponse.error.response))
          // );
          alert('物件価格の検索に失敗しました');
          return;
        }

        this.rooms = apiResponse.data.items;
        if (this.rooms.length < 1) {
          alert(
            '該当する物件が見つかりませんでした。\n物件の諸条件からのシミュレートをお願いします。'
          );
          this.inputReset();
        } else if (this.rooms.length === 1) {
          this.purchasePrice = apiResponse.data.items[0].purchasePrice;
        } else {
          this.rooms.forEach(room => {
            this.minPurchasePrice = Math.min(
              this.minPurchasePrice,
              room.purchasePrice
            );
            this.maxPurchasePrice = Math.max(
              this.maxPurchasePrice,
              room.purchasePrice
            );
          });
        }
      },
      // TODO: 初期値を手打ちしないで管理したい
      inputReset() {
        this.buildingName = undefined;
        this.roomNumber = undefined;
        this.purchasePrice = '---';
        this.minPurchasePrice = Infinity;
        this.maxPurchasePrice = 0;
        this.rooms = [];
      }
    }
  };
</script>
<style scoped>
  @import './css/style.css';
  @import './css/slide.css';
  /* TODO: デザイン調整 */
  .purchase-price {
    color: #ff5252;
  }
  .indent {
    padding-left: 1em;
    text-indent: -1em;
  }
  .search-result-table th {
    color: #ffffff !important;
    background-color: #5b9bd5;
    border-bottom: 3px solid #ffffff !important;
    width: auto;
  }
  .search-result-table th:nth-child(2) {
    border-left: 2px solid #ffffff !important;
  }
  .search-result-table td {
    background-color: #eaeff7;
    border-bottom: 2px solid #ffffff !important;
  }
  .search-result-table tr:nth-child(odd) td {
    background-color: #d2deef;
  }
  .search-result-table td:nth-child(2) {
    color: #ff5252;
    border-left: 2px solid #ffffff !important;
  }
</style>
