<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar elevation="3">
            <span class="text-h5">物件価格査定フォーム</span>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  style="border-right: solid thin; border-color: rgba(0, 0, 0, 0.12);"
                  cols="12"
                  sm="6"
                >
                  <v-container v-if="!fetchComplete">
                    <v-row>
                      <p class="text-h6">データ取得中です</p>
                    </v-row>
                    <v-row justify="center">
                      <v-progress-circular
                        indeterminate
                        size="48"
                      ></v-progress-circular>
                    </v-row>
                  </v-container>
                  <template v-else>
                    <p class="text-h6">物件情報を入力してください。</p>
                    <v-form>
                      <v-container>
                        <span class="text-caption">エリア</span>
                        <v-row>
                          <v-col cols="12">
                            <v-chip-group
                              v-model="selectedRegion"
                              active-class="primary--text"
                              column
                            >
                              <v-chip
                                v-for="region in regions"
                                :key="region"
                                :value="region"
                                x-large
                              >
                                <span class="region-chips-content" :style="maxRegionNameLen">{{ region }}</span>
                              </v-chip>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              name="buildingAge"
                              id="buildingAge"
                              v-model="buildingAge"
                              autocomplete="off"
                              placeholder="例: 6"
                              label="築年数(単位: 年)"
                              clearable
                              autofocus
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              name="totalArea"
                              label="平米数(単位: 平米)"
                              id="totalArea"
                              v-model="totalArea"
                              placeholder="例: 24"
                              autocomplete="off"
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              name="nearStation"
                              label="最寄り駅"
                              id="nearStation"
                              v-model="nearStation"
                              placeholder="例: 三田"
                              autocomplete="off"
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              name="walkingTime"
                              label="徒歩(単位: 分)"
                              id="walkingTime"
                              v-model="walkingTime"
                              placeholder="例: 5"
                              autocomplete="off"
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                      <p>
                        下記内容も分かる方は入力してください。
                      </p>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              name="rentPrice"
                              label="家賃(単位: 円)"
                              id="rentPrice"
                              v-model="rentPrice"
                              placeholder="例: 95000"
                              autocomplete="off"
                              clearable
                            ></v-text-field>
                            <span class="text-caption">※管理会社の手数料が引かれる前の金額</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              name="managementFee"
                              label="管理費・修繕積立金(単位: 円)"
                              id="managementFee"
                              v-model="managementFee"
                              placeholder="例: 13000"
                              autocomplete="off"
                              clearable
                            ></v-text-field>
                            <span class="text-caption">※管理組合に支払っている金額</span>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="6">
                            <template v-if="$vuetify.breakpoint.name!='xs'">
                                <v-btn width="100%" color="info" @click="simulate">買取価格計算</v-btn>
                            </template>
                            <template v-else>
                              <v-btn width="100%" color="info" @click="simulate">買取価格計算</v-btn>
                            </template>
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              width="100%"
                              color="error"
                              @click="inputReset"
                              >リセット</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </template>
                </v-col>
                <!-- NOTE: v-dividerの幅でスタイルが崩れないか要確認 -->
                <v-divider
                  :class="{ 'mx-1': this.$vuetify.breakpoint.xs }"
                  :vertical="!this.$vuetify.breakpoint.xs"
                ></v-divider>
                <v-col id="fixTargetWrapper" cols="12" sm="6">
                  <div id="fixTarget" :style="fixTargetStyle">
                    <p class="text-h6">日本リバックス買取価格(目安)</p>
                    <!-- TODO:レスポンシブ用調整 -->
                    <p
                      :class="{ 'px-6': !this.$vuetify.breakpoint.xs }"
                      class="text-center text-sm-left text-h4 font-weight-bold font-italic purchase-price"
                    >
                      {{ purchasePrice | addComma }} 円
                    </p>
                    <p class="text-caption indent">
                      ※本価格はあくまで参考価格となり、実際の買取金額とは異なることがあります。詳しくはお問合せください。
                    </p>
                    <p class="text-caption indent">
                      ※ご希望の駅が存在しなかった場合、駅追加のリクエストをお問合せください。
                    </p>
                    <p class="text-center text-sm-left">
                      <v-btn
                        color="info"
                        style="width:45%"
                        href="https://nippon-rebax.co.jp/contact/"
                        target="_blank"
                        @click="recordInquiryEvent"
                        >お問合せ</v-btn
                      >
                    </p>
                    <!-- 価格情報の登録が進み次第、コメントアウトを解除 -->
                    <!-- 
                    <p class="text-left text-sm-right text-caption indent">
                      ※マンション名より価格を算出したい場合は<router-link
                        to="/purchase-search"
                        >こちら</router-link
                      >
                    </p>
                    -->
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { API, Analytics } from 'aws-amplify';
  const apiName = 'rebax';

  export default {
    beforeMount() {
      document.title = '物件価格査定フォーム | 株式会社日本リバックス';
    },
    mounted() {
      Analytics.record({
        name: 'シミュレーションページにアクセス'
      });

      this.updateFixTargetStyle();
      window.addEventListener('resize', () => {
        this.updateFixTargetStyle();

        const fixTarget = document.getElementById('fixTarget');
        if(this.$vuetify.breakpoint.name === 'xs' &&
          fixTarget.classList.contains('fixed')
        ) {
          fixTarget.classList.remove('fixed');
        }
      });

      window.addEventListener('scroll', () => {
        const appHeader = document.getElementsByClassName('v-app-bar')[0];
        const fixTargetWrapper = document.getElementById('fixTargetWrapper');
        const fixTarget = document.getElementById('fixTarget');

        if(fixTargetWrapper.getClientRects()[0].y <= appHeader.clientHeight &&
          !fixTarget.classList.contains('fixed')
        ) {
          this.updateFixTargetStyle();
          fixTarget.classList.add('fixed');
        }
        else if(fixTargetWrapper.getClientRects()[0].y > appHeader.clientHeight &&
          fixTarget.classList.contains('fixed')
        ) {
          fixTarget.classList.remove('fixed');
        }
      });
    },
    async created() {
      this.regions = await this.getRegions();
      if (this.regions.length > 0) {
        this.selectedRegion = this.regions[0];
      }

      this.stations = await this.getStations();
      this.fetchComplete = true;
    },
    data() {
      return {
        fetchComplete: false,
        purchasePrice: '---',
        buildingAge: undefined,
        totalArea: undefined,
        regions: [],
        selectedRegion: 0,
        stations: [],
        nearStation: undefined,
        walkingTime: undefined,
        rentPrice: undefined,
        managementFee: undefined,

        priceColmunWidth: '0',
        appHeaderHeight: '0'
      };
    },
    computed: {
      maxRegionNameLen() {
        return {
          '--max-region-name-len': `${this.regions.reduce((pre, cur) => pre > cur.length ? pre : cur.length)}em`
        };
      },
      fixTargetStyle() {
        return {
          '--price-colmun-width': `${this.priceColmunWidth}px`,
          '--app-header-height': `${this.appHeaderHeight}px`
        };
      }
    },
    watch: {},

    methods: {
      async getRegions() {
        const token = await this.$cognito.getJwtToken();

        // APIからデータ取得
        const myInit = {
          response: true,
          headers: { Authorization: token }
        };
        const resourcePath = '/regions';
        const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
          e => ({
            error: e
          })
        );

        if (apiResponse.error) {
          alert('エリアデータの取得に失敗しました');
          return [];
        }

        return apiResponse.data;
      },
      async getStations() {
        const token = await this.$cognito.getJwtToken();

        // APIからデータ取得
        const myInit = {
          response: true,
          headers: { Authorization: token }
        };
        const resourcePath = '/stations';
        const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
          e => ({
            error: e
          })
        );

        if (apiResponse.error) {
          alert('駅データの取得に失敗しました');
          return [];
        }

        return apiResponse.data;
      },
      updateFixTargetStyle() {
        const fixTargetWrapper = document.getElementById('fixTargetWrapper');
        const priceColmunWidth = Number(getComputedStyle(fixTargetWrapper).width.replace('px', ''))
          - Number(getComputedStyle(fixTargetWrapper).paddingLeft.replace('px', ''))
          - Number(getComputedStyle(fixTargetWrapper).paddingRight.replace('px', ''));
        this.priceColmunWidth = priceColmunWidth;

        const appHeader = document.getElementsByClassName('v-app-bar')[0];
        this.appHeaderHeight = appHeader.clientHeight;
      },
      async simulate() {
        // TODO: バリデーションの実行をinputからフォーカスが外れたのタイミングに変更
        // 全てのバリデーションが通った場合に計算ボタンを有効化のほうがベター?
        let errorMsg = [];
        // TODO: 空白だけでできた文字列をNumber()に渡すと0になる点の対応

        /* 築年数 */
        if (isNaN(Number(this.buildingAge))) {
          errorMsg.push('築年数に数値を入力してください');
        } else if (Number(this.buildingAge) > 30) {
          errorMsg.push('築年数は30年以下を入力してください');
        }

        /* 平米数 */
        if (isNaN(Number(this.totalArea))) {
          errorMsg.push('平米数に数値を入力してください');
        } else if (Number(this.totalArea) > 50) {
          errorMsg.push('平米数は50平米以下を入力してください');
        }

        /* 最寄り駅 */
        if (this.nearStation === undefined || this.nearStation === '') {
          errorMsg.push('最寄り駅に駅名を入力してください');
        }
        // 駅とエリアの対応確認
        else if (
          !this.stations[this.selectedRegion].includes(this.nearStation)
        ) {
          errorMsg.push(
            `${this.selectedRegion}エリアには${this.nearStation}駅の登録がありません`
          );

          Object.entries(this.stations).forEach(region => {
            if (region[0] === this.selectedRegion) return;

            if (region[1].includes(this.nearStation)) {
              errorMsg.push(`${region[0]}エリアに同名の駅の登録があります`);
            }
          });
        }

        /* 徒歩 */
        if (isNaN(Number(this.walkingTime))) {
          errorMsg.push('徒歩に数値を入力してください');
        } else if (Number(this.walkingTime) > 20) {
          errorMsg.push('徒歩は20分以下を入力してください');
        }
        // this.rentPrice XOR this.managementFee
        if (
          (this.rentPrice || this.managementFee) &&
          !(this.rentPrice && this.managementFee)
        ) {
          errorMsg.push(
            '家賃と管理費・修繕積立金を利用する場合は両方入力してください。'
          );
        } else {
          if (this.rentPrice !== undefined && isNaN(Number(this.rentPrice))) {
            errorMsg.push('家賃は数値で入力してください');
          }
          if (
            this.managementFee !== undefined &&
            isNaN(Number(this.managementFee))
          ) {
            errorMsg.push('管理費・修繕費は数値で入力してください');
          }
        }

        if (errorMsg.length > 0) {
          alert(errorMsg.join('\n'));
          return;
        }

        // TODO: リクエスト部分の関数化
        const token = await this.$cognito.getJwtToken();

        const queryStringParameters = {
          buildingAge: this.buildingAge,
          totalArea: this.totalArea,
          region: this.selectedRegion,
          nearStation: this.nearStation,
          walkingTime: this.walkingTime
        };
        // 家賃と管理費が両方入力されていれば利用する
        if (this.rentPrice && this.managementFee) {
          queryStringParameters.rentPrice = this.rentPrice;
          queryStringParameters.managementFee = this.managementFee;
        }
        // APIからデータ取得
        const myInit = {
          response: true,
          headers: { Authorization: token },
          queryStringParameters: queryStringParameters
        };
        const resourcePath = '/simulate';
        const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
          e => ({
            error: e
          })
        );
        Analytics.record({
          name: 'シミュレーション実行',
          attributes: queryStringParameters
        });

        if (apiResponse.error) {
          // window.console.log(
          //   JSON.parse(JSON.stringify(apiResponse.error.response))
          // );
          const msg = ['計算結果の取得に失敗しました'];
          // if (apiResponse.error.response.status === 403) {
          //   msg.push('認証エラーです');
          // }
          if (apiResponse.error.response.data.error) {
            apiResponse.error.response.data.error.forEach(e => {
              msg.push(e);
            });
          }
          alert(msg.join('\n'));
          this.purchasePrice = '---';
          return;
        }

        // 単位が1円の実数で返ってくる、10万円未満を切り捨て表示
        this.purchasePrice =
          Math.floor(Number(apiResponse.data.purchasePrice) / 100000) * 100000;
      },
      inputReset() {
        this.buildingAge = undefined;
        this.totalArea = undefined;
        this.selectedRegion = this.regions[0];
        this.nearStation = undefined;
        this.walkingTime = undefined;
        this.purchasePrice = '---';
        this.rentPrice = undefined;
        this.managementFee = undefined;
      },
      recordInquiryEvent() {
        Analytics.record({
          name: 'お問い合わせ'
        });
      }
    }
  };
</script>
<style scoped>
  @import './css/style.css';
  @import './css/slide.css';
  /* TODO: デザイン調整 */
  .purchase-price {
    color: #ff5252;
  }
  .indent {
    padding-left: 1em;
    text-indent: -1em;
  }
  .region-chips-content {
    width: var(--max-region-name-len);
    text-align: center;
  }
  #fixTarget.fixed {
    position: fixed;
    top: var(--app-header-height);
    width: var(--price-colmun-width);
  }
</style>
