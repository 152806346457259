<template>
  <v-container style="max-width: 1300px;">
    <v-row justify="center">
      <v-col xl="9" lg="9" md="12" sm="12" cols="12">
        <v-card>
          <v-card-title class="font-weight-black">
            検索結果
          </v-card-title>
        </v-card>
        <div class="font-weight-bold my-4" style="font-size:1.2em;">
          {{ countApartments }}件
        </div>
        <v-card
          v-for="apartment in apartmentsInfo"
          :key="apartment.id"
          class="mb-7"
        >
          <v-toolbar elevation="3">
            <v-btn
              link
              text
              plain
              color="primary"
              class="font-weight-regular px-0"
              style="font-size:1.5em;"
              to="http://google.com"
            >
              {{ apartment.name }}
            </v-btn>
          </v-toolbar>
          <v-row no-gutters>
            <v-col
              style="border-right: solid thin; border-color: rgba(0, 0, 0, 0.12);"
              xl="8"
              lg="8"
              md="8"
              sm="12"
              cols="12"
            >
              <v-row
                class="py-3 pl-3"
                style="height: 100%;"
                align="center"
                no-gutters
              >
                <v-col cols="5">
                  <v-img :src="apartment.imgPath"> </v-img>
                </v-col>
                <v-col class="pl-3" cols="7">
                  <div>
                    参考相場価格
                  </div>
                  <v-btn
                    link
                    text
                    plain
                    color="red"
                    height="auto"
                    class="font-weight-regular px-0"
                    style="font-size:1.5em; white-space: normal;"
                    to="http://google.com"
                  >
                    {{ apartment.minMarketPrice | addComma }}万円〜{{
                      apartment.maxMarketPrice | addComma
                    }}万円
                  </v-btn>
                  <div>
                    （{{ apartment.minOccupiedArea | addComma }}㎡〜{{
                      apartment.maxOccupiedArea | addComma
                    }}㎡）
                  </div>
                  <div>
                    <font v-for="room in apartment.rooms" :key="room.id">
                      {{ room.houseLayout }}&ensp;/
                    </font>
                  </div>
                  <!-- 以下、card化、レスポンシブ対応が必要な場合に対応 -->
                  <!-- <v-row
                      no-gutters>
                      <v-col
                        class="pr-3"
                        cols="3">
                        <v-card
                          style="min-width:50px;"
                          class="px-1">
                          <v-card-actions class="justify-center pa-0">
                            1LDK
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col
                        class="pr-3"
                        cols="3">
                        <v-card
                          style="min-width:50px;"
                          class="px-1">
                          <v-card-actions class="justify-center pa-0">
                            2LDK
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col
                        class="pr-3"
                        cols="3">
                        <v-card
                          style="min-width:50px;"
                          class="px-1">
                          <v-card-actions class="justify-center pa-0">
                            3LDK
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col
                        class="pr-3"
                        cols="3">
                        <v-card
                          style="min-width:50px;"
                          class="px-1">
                          <v-card-actions class="justify-center pa-0">
                            4LDK
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pt-3 pl-3" xl="4" lg="4" md="4" sm="12" cols="12">
              <div style="font-size:1.0em;" class="mb-3 font-weight-light">
                住所：{{ apartment.address }}
              </div>
              <div class="mb-3 font-weight-light">
                沿線・駅：
                <font
                  v-for="nearStation in apartment.nearStations"
                  :key="nearStation.stationCd"
                >
                  「{{ nearStation.stationName }}駅」
                  {{ nearStation.accessMethod }}{{ nearStation.accessTime }}分／
                </font>
              </div>
              <div class="mb-3 font-weight-light">
                築年数：築{{ apartment.apartmentAge }}年
              </div>
              <div class="mb-3 font-weight-light">
                階建：{{ apartment.story }}
              </div>
              <div class="mb-3 font-weight-light">
                構造：{{ apartment.structure }}
              </div>
            </v-col>
          </v-row>
          <v-divider />
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center">
                  階数
                </th>
                <th class="text-center">
                  参考相場価格
                </th>
                <th class="text-center">
                  新築時価格
                </th>
                <th class="text-center">
                  間取り
                </th>
                <th class="text-center">
                  専有面積
                </th>
                <th class="text-center">
                  主要採光面
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="room in apartment.rooms" :key="room.id">
                <td class="text-center">{{ room.floor }}階</td>
                <td class="text-center">
                  {{ room.marketPrice | addComma }}万円
                </td>
                <td class="text-center">
                  {{ room.newConstructionPrice | addComma }}万円
                </td>
                <td class="text-center">{{ room.houseLayout }}</td>
                <td class="text-center">{{ room.occupiedArea }}㎡</td>
                <td class="text-center">{{ room.lightingSurface }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col xl="3" lg="3" md="12" sm="12" cols="12">
        <v-card class="mb-3">
          <v-card-title>検索条件</v-card-title>
          <v-divider />
          <v-text-field
            placeholder="マンション名・駅名で検索"
            solo
            append-outer-icon
            hide-details
            dense
            class="mx-3 mt-3"
            prepend-inner-icon="fa-search"
          />
          <v-card-actions class="justify-center px-3">
            <v-btn elevation="6" color="primary" block>
              検索する
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card>
          <v-card-title>条件を絞り込む</v-card-title>
          <v-divider class="mb-3" />
          <v-card-actions class="pl-4 py-0">
            <v-icon class="mr-1">fa-map-marker-alt</v-icon>
            <v-card-subtitle class="pa-0">都道府県</v-card-subtitle>
          </v-card-actions>
          <v-card-actions class="justify-center px-3 mb-4 py-0">
            <v-select
              :items="prefectureItems"
              v-model="prefecture"
              return-object
              label="指定なし"
              clearable
              solo
              hide-details
            />
          </v-card-actions>

          <v-card-actions class="pl-4 py-0">
            <v-icon class="mr-1">fa-yen-sign</v-icon>
            <v-card-subtitle class="pa-0">価格</v-card-subtitle>
          </v-card-actions>
          <v-card-actions class="justify-center px-3 mb-4 py-0">
            <v-select
              :items="priceItems"
              v-model="minPrice"
              return-object
              label="下限なし"
              clearable
              solo
              hide-details
              class="justify-center mr-1"
            />
            ～
            <v-select
              :items="priceItems"
              v-model="maxPrice"
              return-object
              label="上限なし"
              clearable
              solo
              hide-details
              class="justify-center ml-1"
            />
          </v-card-actions>

          <v-card-actions class="pl-4 py-0">
            <v-icon class="mr-1">fa-ruler-combined</v-icon>
            <v-card-subtitle class="pa-0">専有面積</v-card-subtitle>
          </v-card-actions>
          <v-card-actions class="justify-center px-3 mb-4 py-0">
            <v-select
              :items="occupiedAreaItems"
              v-model="minOccupiedArea"
              return-object
              label="下限なし"
              clearable
              solo
              hide-details
              class="justify-center mr-1"
            />
            ～
            <v-select
              :items="occupiedAreaItems"
              v-model="maxOccupiedArea"
              return-object
              label="上限なし"
              clearable
              solo
              hide-details
              class="justify-center ml-1"
            />
          </v-card-actions>

          <v-card-actions class="pl-4 py-0">
            <v-icon class="mr-1">fa-border-style</v-icon>
            <v-card-subtitle class="pa-0">間取り</v-card-subtitle>
          </v-card-actions>
          <v-card-actions class="justify-center px-3 mb-4 py-0">
            <v-select
              :items="houseLayoutItems"
              v-model="minHouseLayout"
              return-object
              label="下限なし"
              clearable
              solo
              hide-details
              class="justify-center mr-1"
            />
            ～
            <v-select
              :items="houseLayoutItems"
              v-model="maxHouseLayout"
              return-object
              label="上限なし"
              clearable
              solo
              hide-details
              class="justify-center ml-1"
            />
          </v-card-actions>

          <v-card-actions class="pl-4 py-0">
            <v-icon class="mr-1">fa-train</v-icon>
            <v-card-subtitle class="pa-0"
              >駅までの徒歩・バス乗車時間</v-card-subtitle
            >
          </v-card-actions>
          <v-card-actions class="justify-center px-3 mb-2 py-0">
            <v-select
              :items="accessTimeItems"
              v-model="maxAccessTime"
              return-object
              label="下限なし"
              clearable
              solo
              hide-details
              class="justify-center mr-1"
            />
            <v-checkbox
              v-model="isIncludingBus"
              label="バスを含む"
              style="width:250px;"
              class="ml-3"
            />
          </v-card-actions>

          <v-card-actions class="pl-4 py-0">
            <v-icon class="mr-1">fa-home</v-icon>
            <v-card-subtitle class="pa-0">築年数</v-card-subtitle>
          </v-card-actions>
          <v-card-actions class="justify-center px-3 mb-4 py-0">
            <v-select
              :items="apartmentAgeItems"
              v-model="maxApartmentAge"
              return-object
              label="指定なし"
              clearable
              solo
              hide-details
            />
          </v-card-actions>

          <v-card-actions class="justify-center px-3">
            <v-btn elevation="6" color="primary" block v-on:click="search">
              検索する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import Home from './components/CompanyHomepage/Home.vue'
  // import Company from './components/CompanyHomepage/Company.vue'
  // import Service from './components/CompanyHomepage/Service.vue'
  // import Recruit from './components/CompanyHomepage/Recruit.vue'
  // import Contact from './components/CompanyHomepage/Contact.vue'

  // document.title = '物件を探す | 株式会社日本リバックス';

  const awsApiPath =
    'https://ag1f1sfdfd.execute-api.ap-northeast-1.amazonaws.com/production';
  //document.selectedMenu;

  export default {
    data() {
      return {
        selectedMenu: 0,
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        prefectureItems: [
          { text: '東京都', value: '13' },
          { text: '神奈川県', value: '14' },
          { text: '埼玉県', value: '11' },
          { text: '千葉県', value: '12' }
        ],
        priceItems: [
          { text: '500万', value: '500' },
          { text: '1000万', value: '1000' },
          { text: '1500万', value: '1500' },
          { text: '2000万', value: '2000' },
          { text: '2500万', value: '2500' },
          { text: '3000万', value: '3000' },
          { text: '3500万', value: '3500' },
          { text: '4000万', value: '4000' },
          { text: '4500万', value: '4500' },
          { text: '5000万', value: '5000' },
          { text: '5500万', value: '5500' },
          { text: '6000万', value: '6000' },
          { text: '6500万', value: '6500' },
          { text: '7000万', value: '7000' },
          { text: '7500万', value: '7500' },
          { text: '8000万', value: '8000' },
          { text: '8500万', value: '8500' },
          { text: '9000万', value: '9000' },
          { text: '9500万', value: '9500' },
          { text: '10000万', value: '10000' }
        ],
        occupiedAreaItems: [
          { text: '0㎡', value: '0' },
          { text: '10㎡', value: '10' },
          { text: '20㎡', value: '20' },
          { text: '30㎡', value: '30' },
          { text: '40㎡', value: '40' },
          { text: '50㎡', value: '50' },
          { text: '60㎡', value: '60' },
          { text: '70㎡', value: '70' },
          { text: '80㎡', value: '80' },
          { text: '90㎡', value: '90' },
          { text: '100㎡', value: '100' }
        ],
        houseLayoutItems: [
          '1R',
          '1K',
          '1DK',
          '1LDK',
          '2K',
          '2DK',
          '2LDK',
          '3K',
          '3DK',
          '3LDK',
          '4K',
          '4DK',
          '4LDK',
          '4LDK以上'
        ],
        accessTimeItems: [
          { text: '1分以内', value: '1' },
          { text: '3分以内', value: '3' },
          { text: '5分以内', value: '5' },
          { text: '7分以内', value: '7' },
          { text: '10分以内', value: '10' },
          { text: '15分以内', value: '15' },
          { text: '20分以内', value: '20' }
        ],
        apartmentAgeItems: [
          { text: '3年以内', value: '3' },
          { text: '5年以内', value: '5' },
          { text: '10年以内', value: '10' },
          { text: '15年以内', value: '15' },
          { text: '20年以内', value: '20' },
          { text: '25年以内', value: '25' },
          { text: '30年以内', value: '30' }
        ],
        isIncludingBus: false,
        Rooms: [],
        Apartments: [],
        Stations: [],
        prefecture: undefined,
        minPrice: undefined,
        maxPrice: undefined,
        minOccupiedArea: undefined,
        maxOccupiedArea: undefined,
        minHouseLayout: undefined,
        maxHouseLayout: undefined,
        maxAccessTime: undefined,
        maxApartmentAge: undefined,
        test: {},
        testtest: [],
        testtesttest: [],
        testtesttesttest: {}
      };
    },
    computed: {
      /*
       * 画面表示に使用する建物情報
       */
      apartmentsInfo() {
        // Apartmentsをループ処理し、返却結果をreturnする
        return this.Apartments.map(apartment => {
          // Apartmentsのオブジェクトが変更されないよう、複製する
          const returnApartment = { ...apartment };

          // apartment.nearStationsに関する処理
          this.addNearStationsInfo(returnApartment);

          // apartmentに部屋情報を追加する
          this.addApartmentRooms(returnApartment);

          // 最低相場価格と最高相場価格を追加する

          return returnApartment;
        });
      },

      /*
       * 建物の件数
       */
      countApartments() {
        return this.Apartments.length;
      },

      /**
    / メニューの表示、非表示制御用変数
    / 配列内容：0:Home,1:Company,2:Service,3:Recruite,4:Link,5:Contract
    / 
    **/
      isActive() {
        var numberOfMenu = 5; // メニューの数
        var arrResult = [];
        for (var i = 0; i < numberOfMenu; i++) {
          arrResult.push(false);
        }

        arrResult[this.selectedMenu] = true;

        return arrResult;
      }
    },
    methods: {
      /*
       * 詳細検索
       */
      async search() {
        // 条件にあったデータを取得する
        const getApartments = await this.axios.get(awsApiPath + '/apartments', {
          // クエリパラメータを設定
          params: this.removeUndefinedFromObject({
            // セレクトボックスが選択されていればvalueが設定され、選択されていなければundefined
            prefCd: this.prefecture ? this.prefecture.value : undefined, // 都道府県
            minPrice: this.minPrice ? this.minPrice.value : undefined, // 価格（下限）
            maxPrice: this.maxPrice ? this.maxPrice.value : undefined, // 価格（上限）
            minOccupiedArea: this.minOccupiedArea
              ? this.minOccupiedArea.value
              : undefined, // 専有面積（下限）
            maxOccupiedArea: this.maxOccupiedArea
              ? this.maxOccupiedArea.value
              : undefined, // 専有面積（上限）
            minHouseLayout: this.minHouseLayout
              ? this.minHouseLayout
              : undefined, // 間取り（下限）
            maxHouseLayout: this.maxHouseLayout
              ? this.maxHouseLayout
              : undefined, // 間取り（上限）
            maxAccessTime: this.maxAccessTime
              ? this.maxAccessTime.value
              : undefined, // 乗車時間（下限）
            maxApartmentAge: this.maxApartmentAge
              ? this.maxApartmentAge.value
              : undefined // 築年数（上限）
          })
        });

        this.makeApartmentInfo(getApartments.data.apartments);
      },

      /*
       * オブジェクトの要素がundefinedを対象に削除
       */
      removeUndefinedFromObject(object) {
        return Object.fromEntries(
          Object.entries(object).filter(([, v]) => v !== undefined)
        );
      },

      /*
       * apartment情報を受け取り、建物の情報を作成する
       */
      async makeApartmentInfo(apartments) {
        // 建物情報を設定する
        this.Apartments = apartments;

        // 建物に紐づく情報を設定する
        for (let apartment of apartments) {
          /** 部屋情報編集 **/
          // すでに持っていない部屋情報のみを取得し、追加する
          if (!this.Rooms.some(room => room.apartmentId === apartment.id)) {
            // 建物情報より部屋情報を取得し、Roomsに部屋情報を追加
            const getRooms = await this.axios.get(
              awsApiPath + '/apartments/' + apartment.id + '/rooms'
            );
            this.Rooms.push(...getRooms.data.rooms);
          }

          /** 駅情報編集 **/
          // 建物情報のnearStationsより周辺の駅情報を取得し、Stationsに追加
          for (let nearStation of apartment.nearStations) {
            // すでに持っていない駅情報をのみ取得し、追加する
            if (
              !this.Stations.some(
                station => station.stationCd === nearStation.stationCd
              )
            ) {
              const getStation = await this.axios.get(
                awsApiPath + '/stations/' + nearStation.stationCd
              );
              this.Stations.push(getStation.data.station);
            }
          }
        }
      },

      /*
       * apartment情報を受け取り、nearStationsに詳細情報を追加する
       */
      addNearStationsInfo(apartment) {
        if (apartment.nearStations) {
          // nearStationsをループ処理し、返却結果を設定する
          apartment.nearStations = apartment.nearStations.map(nearStation =>
            // 空のオブジェクトにnearStationとstationCdで紐づくStationsオブジェクトを追加したものを返却
            Object.assign(
              {},
              nearStation,
              this.Stations.find(
                station => station.stationCd === nearStation.stationCd
              )
            )
          );
        }
      },

      /*
       * apartment情報を受け取り、room情報を追加する
       */
      addApartmentRooms(apartment) {
        // apartmentIdが一致する部屋でフィルターをかける
        const filterRooms = this.Rooms.filter(
          room => room.apartmentId == apartment.id
        );

        // roomから情報を抽出して配列を作成
        const marketPrices = filterRooms.map(room => room.marketPrice); // 相場価格
        const occupiedAreas = filterRooms.map(room => +room.occupiedArea); // 専有面積

        Object.assign(apartment, {
          rooms: filterRooms,
          minMarketPrice: Math.min.apply(null, marketPrices),
          maxMarketPrice: Math.max.apply(null, marketPrices),
          minOccupiedArea: Math.min.apply(null, occupiedAreas),
          maxOccupiedArea: Math.max.apply(null, occupiedAreas)
        });
      }
    },
    mounted() {
      // 画面表示時に建物情報を取得する
      this.axios.get(awsApiPath + '/apartments').then(async response => {
        this.makeApartmentInfo(await response.data.apartments);
      });
    },
    components: {
      // Home,
      // Company,
      // Service,
      // Recruit,
      // Contact
    }
  };
</script>
<style>
  @import './css/style.css';
  @import './css/slide.css';
</style>
